/* eslint no-unused-expressions: 0, no-undef: 0 */
import { Prefetcher, prefetchable } from 'webpack-prefetcher';
import device from '~/helpers/device';

declare const window: { manifestPath: string };

// manifestPath is defined in public/index.html which is being overwritten by a custom webpack plugin in our cra
Prefetcher.loadManifest(fetch(`/${window.manifestPath}`).then(res => res.json()));

// Common chunks
// const SessionsController = prefetchable(() => import('./api/controllers/SessionsController'), 'SessionsController');

// Mobile chunks
const LayoutPartialMobile = prefetchable(() => import('./partials/LayoutPartial/mobile'), 'LayoutPartial.mobile');
const HeaderPartialMobile = prefetchable(() => import('./partials/HeaderPartial/mobile'), 'HeaderPartial.mobile');

// DR chunks
const DailyRecommendationsPage = prefetchable(() => import('./pages/DailyRecommendationsPage'), 'DailyRecommendationsPage');
const ProfilePageMobile = prefetchable(() => import('./pages/ProfilePage/mobile'), 'ProfilePage.mobile');
const SearchPartnerMobile = prefetchable(() => import('./pages/SearchPartnerPage/mobile'), 'SearchPartnerPage');
const doProfileActionMobile = prefetchable(() => import('./actions/doProfileAction/mobile'), 'doProfileAction.mobile');
// const doProfileAction = prefetchable(() => import('./actions/doProfileAction'), 'doProfileAction');
// const ProfilesController = prefetchable(() => import('./api/controllers/ProfilesController'), 'ProfilesController');
// const ProfileQueuesController = prefetchable(() => import('./api/controllers/ProfileQueuesController'), 'ProfileQueuesController');

export const prefetchMobileProfilePage = (): void => {
  ProfilePageMobile.prefetch('script');
  doProfileActionMobile.prefetch('script');
};

type PrefetchPageChunks = {
  platform: string;
  location: Location;
};

// TODO: add specs for this
const prefetchPageChunks = ({ platform, location }: PrefetchPageChunks): void => {
  // NOTE: Not preloading layout and header partials ensure these
  // don't compete for browser resources and makes profile to render faster
  // Preloading header and header partials though make overall page faster
  // they delay largest contentful paint which is subjectively more meaningful

  // LayoutPartialMobile.preload('script');
  // HeaderPartialMobile.preload('script');

  switch (location.pathname) {
    case '/profile/daily-recommendations': {
      LayoutPartialMobile.preload('script');
      HeaderPartialMobile.preload('script');
      DailyRecommendationsPage.preload('script');
      if (platform === 'mobile') {
        ProfilePageMobile.preload('script');
        doProfileActionMobile.prefetch('script');
      }
      break;
    }
    case '/search/partner': {
      if (platform === 'mobile') {
        SearchPartnerMobile.preload();
      }
      break;
    }
    default: {
      break;
    }
  }
};

// @ts-ignore devise file is not on typescript
prefetchPageChunks({ platform: device.platform, location: window.location });

export default prefetchPageChunks;
